import React, { useEffect, useState } from "react";
import { graphql, Link, navigate } from "gatsby";

import { IoIosArrowRoundForward } from "react-icons/io";
import { Container, Row, Col } from "reusecore/Layout";
import { multiFilter } from './utils';

import PageHeader from "reusecore/PageHeader";
import Sidebar from "../Blog-sidebar";
import Img from "gatsby-image"
import Fuse from 'fuse.js'
import blogThumb1 from "assets/images/blog/post/blog-post-1.png";
import blogThumb2 from "assets/images/blog/post/blog-post-2.png";
import blogThumb3 from "assets/images/blog/post/blog-post-3.png";
import blogThumb4 from "assets/images/blog/post/blog-post-4.png";
import blogThumb5 from "assets/images/blog/post/blog-post-5.png";
import blogThumb6 from "assets/images/blog/post/blog-post-6.png";

import { BlogPageWrapper } from "./blogGrid.style";
import { Tag } from "antd";
import { useLocation } from "react-use";

const BlogPage = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const [filtered, setFiltered] = useState(data)
  const fuse = new Fuse(data, {
    keys: ['title', 'subtitle', 'description', 'tags']
  })
  const location = useLocation();
  const {state} = location
  console.log(state)

  useEffect(() => {
    if(state.searchParams) {
      setFiltered(fuse.search(state.searchParams).map(item => item.item))
    }
  },[])
  const onFilter = (value) => {
    return value ? setFiltered(fuse.search(value).map(item => item.item)) : setFiltered(data)
  }


  console.log(filtered)
  return (
    <BlogPageWrapper>
      <PageHeader title="Blogs" subtitle="Find out what's new in the zoo at Sortd." />

      <div className="blog-page-wrapper">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <div className="blog-grid-wrapper">
                <Row>
                    {filtered.map(blog => (
                    <Col xs={12} sm={6}>
                      <div className="post-block">
                      <div className="post-thumb-block">
                        <Link className="anchor" to={blog.slug}>
                        <Img className={"gatsbyHero"} fluid={blog.thumbnail?.childImageSharp.fluid} style={{ transition: 'all 0.6s ease !important'}}/>
                        </Link>
                      </div>
                      <div className="post-content-block">
                        <div className="post-meta-block">
                          <span>{blog.author}</span>
                          <span className="divider">/</span>
                          <span>{blog.time}</span>
                          <span className="divider">/</span>
                          <span>{blog.readingTime?.text}</span>
                        </div>
                        <h2 className="post-title">
                          <Link className="anchor" to={blog.slug}>
                            {blog.title}
                          </Link>
                        </h2>
                        <p className="post-entry">
                          {blog.intro}
                        </p>
                        <Link className="readmore-btn" to={blog.slug}>
                          see more <IoIosArrowRoundForward />
                        </Link>
                      </div>
                    </div>
                  </Col>
                    ))}
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <Sidebar data={data} onFilter={onFilter}/>
            </Col>
          </Row>
        </Container>
      </div>
    </BlogPageWrapper>
  );
};

export default BlogPage;