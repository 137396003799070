import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "components/layout"
import SEO from "components/seo"

import Navigation from "sortd_sections/landing/Navigation"
import BlogPage from "sortd_sections/blog/Blog-grid"
import Footer from "sortd_sections/shared/Footer"

import { GlobalStyle } from "sortd_sections/shared/app.style"
import theme from "theme/blog/themeStyles"

const BlogGridPage = ({data}) => {
  const { allMarkdownRemark } = data; // data.markdownRemark holds our post data
  const { edges = [] } = allMarkdownRemark;
  const blogs = edges.map(({ node, next, previous }) => {
    const { fields: nodeFields } = node;
    return {
      slug: nodeFields.slug,
      meta: {
        ...node.frontmatter,
        slug: nodeFields.slug,
        filename: nodeFields.slug,
      },
      ...node.frontmatter,
      ...node.fields,
      filename: nodeFields.path,
      next,
      previous,
    };
  });
  console.log(blogs)
  return(
  <ThemeProvider theme={theme}>
    <Layout>
      <GlobalStyle />
      <SEO title="Sortd Blog" />
      <BlogPage data={blogs}/>
    </Layout>
  </ThemeProvider>
  )
}
export default BlogGridPage


export const pageQuery = graphql`
  query BlogPageQuery {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/blog/"}, fields: {slug: {}}, frontmatter: {}}, 
    sort: {fields: [frontmatter___order, frontmatter___type], order: DESC}) {
    edges {
      node {
        frontmatter {
          title
          order
          type
          time(formatString: "DD MMM 'YY")
          thumbnail{
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          intro
          author
          tags
        }
        fields {
          slug
          path
          modifiedTime
          readingTime {
            text
          }
        }
      }
    }
  }
}`;
