import React from 'react';
import { AiOutlineLogin } from "react-icons/ai";


const Data = {
    menuItems: [
      {
        name: "Home",
        path: "/#home",
      },
      {
        name: "Products",
        path: "/#products",
        isProducts: true,
        subItems: [
          {
            name: "Change Pro",
            path: "/products/change-pro",
          },
          {
            name: "Requirements Pro",
            path: "/products/requirements-pro",
          },
        ]
      },
      {
        name: "Features",
        path: "/#features",
        offset: "-50"
      },
      {
        name: "Blog",
        path: "/blog-grid"
      },
      {
        name: "Login",
        icon: <AiOutlineLogin style={{verticalAlign: 'middle'}} />,
        path: "https://portal.sortd.io",
        offset: "-50",
        subItems: [
          {
            name: "Change Pro",
            path: "https://portal.sortd.io",
          },
          {
            name: "Requirements Pro",
            path: "https://portal.sortd.io",
          },
        ]
      }
    ]
  };
  export default Data;
  